// Wait for the DOM to load
document.addEventListener("DOMContentLoaded", function() {
  // Get all elements with the "fade-in" class
  const elements = document.querySelectorAll(".fade-in");
  const cursorDot = document.querySelector("[data-cursor-dot]");
  const cursorOutline = document.querySelector("[data-cursor-outline]");

  const initialCircleSize = 32; // Adjust the initial circle size as needed
  const enlargedCircleSize = 200; // Adjust the enlarged circle size as needed

  const enlargeElements = document.querySelectorAll(".projectcard");

  cursorDot.style.cursor = 'none'; // Hide the system cursor for cursorDot
  cursorOutline.style.cursor = 'none'; // Hide the system cursor for cursorOutline

  // Check if element is in the viewport
  function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.bottom >= 0 &&
      rect.right >= 0 &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.left <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  // Function to handle element loading with staggered fade-in effect
  function handleElementLoading() {
    elements.forEach(function(element, index) {
      if (isInViewport(element)) {
        setTimeout(function() {
          element.classList.add("fade-in-visible");
        }, index * 200); // Adjust the delay (in milliseconds) between each element
      }
    });
  }

  // Function to handle mouse movement
  function handleMousemove(event) {
    const posX = event.pageX - document.body.offsetLeft;
    const posY = event.pageY - document.body.offsetTop;

    cursorDot.style.left = `${posX}px`;
    cursorDot.style.top = `${posY}px`;

    let isEnlarged = false;
    enlargeElements.forEach(function(element) {
      if (element.contains(event.target)) {
        isEnlarged = true;
      }
    });

    if (isEnlarged) {
      cursorOutline.style.width = `${enlargedCircleSize}px`;
      cursorOutline.style.height = `${enlargedCircleSize}px`;
      cursorOutline.style.background = '#1e1e1e';
      cursorOutline.style.left = `${posX - (enlargedCircleSize - initialCircleSize) / 2}px`;
      cursorOutline.style.top = `${posY - (enlargedCircleSize - initialCircleSize) / 2}px`;
      cursorOutline.animate(
        {
          width: `${enlargedCircleSize}px`,
          height: `${enlargedCircleSize}px`,
          left: `${posX}px`,
          top: `${posY}px`,
        },
        { duration: 3000, fill: "forwards" }
      );
    } else {
      cursorOutline.style.width = `${initialCircleSize}px`;
      cursorOutline.style.height = `${initialCircleSize}px`;
      cursorOutline.style.background = 'transparent';
      cursorOutline.animate(
        {
          width: `${initialCircleSize}px`,
          height: `${initialCircleSize}px`,
          left: `${posX}px`,
          top: `${posY}px`,
        },
        { duration: 900, fill: "forwards" }
      );
    }
  }

  // Trigger element loading on page load
  handleElementLoading();

  // Add event listener to track mouse movement relative to the body element
  document.body.addEventListener('mousemove', handleMousemove);

  // Add event listener to track scroll movement
  window.addEventListener('scroll', handleMousemove);
});
